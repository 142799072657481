// deno-lint-ignore-file no-namespace

import { ComponentChildren } from 'preact';
import MaybeSignal from 'api/types/MaybeSignal.ts';
import { computed, signal } from '@preact/signals';
import Promiseable from 'api/types/Promiseable.ts';
import { useErrorBoundary, useRef } from 'preact/hooks';
import HydroButton from 'islands/button/HydroButton.tsx';
import { Dearrayify } from 'api/types/Arrayify.ts';
import ExtendStrings from 'api/types/String.ts';
import { ExtendedNatural } from 'api/types/Natural.ts';
import RequestMethod from 'api/request/RequestMethod.ts';
import IconCircleCheck from 'tabler-icons/circle-check.tsx';
import IconCircleX from 'tabler-icons/circle-x.tsx';
import IconChevronRight from 'tabler-icons/chevron-right.tsx';
import IconSwipe from 'tabler-icons/swipe.tsx';
import MouseButton from 'api/event/MouseButton.ts';

const ids = new Set<string>();

const dev = signal<boolean | undefined>(undefined);

const errors = signal<
    {
        id: string;
        error: Error;
        success: boolean;
        handler: (error?: Error) => Promiseable<void>;
        onClose: (error?: Error) => Promiseable<void>;
    }[]
>([]);

function Errors(
    { children }: { children?: MaybeSignal<ComponentChildren> },
) {
    const [error, reset] = useErrorBoundary((error: Error) => {
        console.error(error);

        const origin = globalThis.location?.origin;
        if (!origin) {
            return;
        }

        const { value } = dev;
        switch (value) {
            case true: {
                return Errors.push({ error });
            }

            case false:
                return;

            default:
                fetch(new URL('/api/dev', origin), { method: RequestMethod.HEAD }).then((r) => {
                    const { ok } = r;
                    dev.value = ok;

                    if (ok) {
                        Errors.push({ error });
                    }
                }).catch(console.warn);
        }
    });

    const last = computed(() => {
        const { value } = errors;
        return value[value.length - 1];
    });

    const message = computed(() => {
        const { error } = last.value ?? {};
        const { message } = error ?? {};

        return message;
    });

    const success = computed(() => {
        const { success } = last.value ?? {};

        return success;
    });

    const length = computed(() => {
        const { value } = errors;
        const { length } = value;

        return length;
    });

    const icon = computed(() => length.value == 1 ? <IconChevronRight size={24} /> : <IconSwipe size={24} />);

    const ref = useRef<HTMLDivElement>(null);

    const classes = {
        show: ['z-50', 'right-0', 'opacity-100', 'scale-100', 'visible'],
        hide: ['z-[-1]', '-right-36', 'opacity-0', 'scale-0', 'invisible'],
        animate: [
            'transition-all',
            'ease-in-out',
            'duration-500',
        ],
    } as const;

    return (
        <>
            <div
                ref={ref}
                title={message}
                className={computed(() =>
                    `grid grid-cols-1 absolute bg-stone-50 max-w-[30%] top-1/4 shadow-lg ${
                        (length.value > 0 ? classes.show : classes.hide).join(' ')
                    } ${classes.animate.join(' ')}`
                )}
            >
                <div className='h-12 w-full flex flex-row flex-grow flex-1 gap-4 items-center justify-items-start px-3 py-2'>
                    <HydroButton
                        bounce={false}
                        title={'Close this error message (press Ctrl+click or mouse wheel to close them all).'}
                        onMouseDown={(e: MouseEvent) => {
                            if (e.ctrlKey || e.button == MouseButton.Middle) {
                                Errors.pop(0, length.value - 1);
                            } else {
                                Errors.pop(length.value - 1);
                            }
                        }}
                        icon={icon}
                        color={{ bg: 'transparent', text: 'neutral-700' }}
                    />

                    <div className='w-full flex flex-row flex-grow gap-3 items-center justify-items-end justify-end'>
                        <p
                            className={computed(() =>
                                `w-[${
                                    errors.value?.map(({ error }) => error?.message).reduce(
                                        (acc, sub) => Math.max(acc, sub?.length ?? 0),
                                        0,
                                    )
                                }ch] whitespace-nowrap text-end text-ellipsis overflow-hidden`
                            )}
                        >
                            {message}
                        </p>

                        {success.value && <IconCircleCheck size={24} class={'text-green-700'} />}
                        {!success.value && <IconCircleX size={24} class={'text-red-700'} />}
                    </div>
                </div>

                <div className={computed(() => `w-full h-2 ${success.value ? 'bg-green-700' : 'bg-red-700'}`)}></div>
            </div>

            {children}
        </>
    );
}

namespace Errors {
    export function push(text: string, timeout?: ExtendedNatural): typeof errors.value[number]['id'];
    export function push(error: Error, timeout?: ExtendedNatural): typeof errors.value[number]['id'];
    export function push(error: {
        error: typeof errors.value[number]['error'] | string;
        success?: typeof errors.value[number]['success'];
        handler?: typeof errors.value[number]['handler'];
        onClose?: typeof errors.value[number]['onClose'];
    }, timeout?: ExtendedNatural): typeof errors.value[number]['id'];

    export function push(
        arg:
            | {
                error: typeof errors.value[number]['error'] | string;
                success?: typeof errors.value[number]['success'];
                handler?: typeof errors.value[number]['handler'];
                onClose?: typeof errors.value[number]['onClose'];
            }
            | string
            | typeof errors.value[number]['error'],
        timeout: ExtendedNatural = Infinity,
    ) {
        ExtendStrings();

        const MAX_ITERS = 32768 as const;

        if (typeof arg == 'string' || arg instanceof Error) {
            arg = { error: arg };
        }

        let { error, success, handler, onClose } = arg;

        if (typeof error == 'string') {
            error = new Error(error);
        }

        success ??= false;
        handler ??= () => {};
        onClose ??= () => {};

        let id: string | undefined = undefined;
        for (let i = 0; i < MAX_ITERS; i++) {
            id = crypto.getRandomValues(new Uint8Array(8)).toBase64();
            if (!ids.has(id)) {
                ids.add(id);
                break;
            }
        }

        if (!id) {
            return undefined;
        }

        errors.value = [...errors.value, { id, error, success, handler, onClose }];
        handler();

        if (timeout < 0 || isNaN(timeout)) {
            timeout = Infinity;
        }

        if (isFinite(timeout)) {
            setTimeout(() => Errors.pop(id!), timeout);
        }

        return id;
    }

    export function pop(): Omit<typeof errors.value[number], 'id'> | undefined;
    export function pop(id: string): Omit<typeof errors.value[number], 'id'> | undefined;
    export function pop(index: number): Omit<typeof errors.value[number], 'id'> | undefined;
    export function pop(start: number, end: number): Omit<typeof errors.value[number], 'id'>[] | undefined;

    export function pop(first?: string | number, second?: number) {
        first ??= 0;

        if (typeof first == 'string') {
            const id = first;

            if (!ids.delete(id)) {
                return undefined;
            }

            first = errors.value.findIndex((x) => x.id == id);
            second = undefined;
        }

        if (typeof second == 'undefined') {
            return Dearrayify(pop(first, first + 1));
        }

        if (first > second) {
            [second, first] = [first, second];
        }

        const e = errors.value.slice(first, second).map(({ error, success, handler, onClose }) => ({
            error,
            success,
            handler,
            onClose,
        }));

        if (!e.length) {
            return undefined;
        }

        errors.value = [...errors.value.slice(0, first), ...errors.value.slice(second)];
        e.forEach(({ onClose }) => onClose());

        return e;
    }
}

export default Errors;
